import React from 'react';
import { Button as ChakraButton, Center, Box } from '@chakra-ui/react';
import withLink from '@components/atoms/withLink';
import { ArrowRightIcon } from '@components/atoms/icons';

const ButtonIconCircle = () => (
  <Center
    as="span"
    width="9"
    height="9"
    bg="white"
    ml=".625rem"
    borderRadius="100%"
    transition=".3s"
    sx={{
      '.chakra-button:hover &, .button-hover:hover &, .chakra-menu__menu-button:hover &':
        {
          bg: `black`
        },
      '.chakra-button:hover & > svg, .button-hover:hover & > svg, .chakra-menu__menu-button:hover &  > svg':
        {
          color: `white`
        }
    }}>
    <ArrowRightIcon color="colours.colour-1" w="4" h="4" />
  </Center>
);

const Button = ({
  children = null,
  variant = `primary`,
  size = { base: `sm`, md: `md` },
  icon,
  ...props
}) => {
  return (
    <ChakraButton
      variant={variant}
      size={size}
      iconSpacing=".625rem"
      {...props}>
      {children && (
        <Box
          position="relative"
          as="span"
          // Vertically center text with padding to accomodate for odd line height.
          pt=".125em"
          _after={{
            content: `""`,
            position: `absolute`,
            left: `0`,
            bottom: `0`,
            width: `100%`,
            height: `.0625rem`,
            backgroundColor: `white`,
            opacity: `0`,
            transition: `opacity .3s ease-in-out`
          }}
          sx={{
            '.button-hover:hover &': {
              '&::after': {
                opacity: 1
              }
            }
          }}>
          {children}
        </Box>
      )}
      {icon && <ButtonIconCircle />}
    </ChakraButton>
  );
};

export default Button;

export const ButtonLink = withLink(Button);
