import React, { useContext } from 'react';
import { Link as GatsbyLink, graphql, useStaticQuery } from 'gatsby';
import { Box, AspectRatio, Heading, Hide, HStack } from '@chakra-ui/react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SliderArrows from '@components/molecules/SliderArrows';
import SliderPagination from '@components/molecules/SliderPagination';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import { LocaleContext } from '@helpers/LocaleProvider';
import Glow from '@assets/images/ellipse-glow.png';
import LazyLoad from '../atoms/LazyLoad';

const ProductCarousel = ({ data: { products } }) => {
  // Sets the current locale .
  const { locale } = useContext(LocaleContext);

  // Query allStrapiProductListing to get slug of product listing page.
  const { allStrapiProductListing } = useStaticQuery(graphql`
    {
      allStrapiProductListing {
        nodes {
          locale
          slug
        }
      }
    }
  `);

  // Find matching locale.
  const strapiProductListing = allStrapiProductListing.nodes.find(
    (node) => node.locale === locale
  );

  if (typeof strapiProductListing === `undefined`) {
    console.error(`Unable to find product listing page for ${locale}.`);
    return null;
  }

  const basePath = `${locale}/${strapiProductListing.slug}`;

  return (
    <Section
      overflow="hidden"
      sx={{
        '.swiper': {
          py: 1, // to prevent overflow hidden cropping focus state on buttons.
          overflow: `visible`
        },
        '.swiper-wrapper': {
          width: `fit-content` // to ensure correct width is set on slider wrapper and dragging works.
        }
      }}>
      <LazyLoad>
        <Wrapper>
          <Swiper
            modules={[Navigation, Pagination]}
            navigation={{
              prevEl: `.prev`,
              nextEl: `.next`
            }}
            pagination={{ el: `.pagination`, clickable: true }}
            spaceBetween={25}
            slidesPerView={1}
            breakpoints={{
              600: {
                slidesPerView: 1.75,
                spaceBetween: 30
              },
              768: {
                slidesPerView: 2.1,
                spaceBetween: 48
              },
              1024: {
                slidesPerView: 2.5,
                spaceBetween: 48
              },
              1200: {
                slidesPerView: 2.6,
                spaceBetween: 48
              }
            }}>
            {products &&
              products.map((product) => (
                <SwiperSlide key={product.title} className="product-hover">
                  <Box
                    as={GatsbyLink}
                    to={`/${basePath}/${product.slug}`}
                    display="block"
                    position="relative"
                    transition="transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)"
                    sx={{
                      '.product-hover:hover &': {
                        transform: { md: `auto` },
                        scale: { md: `1.1` }
                      }
                    }}>
                    {product.thumbnail && (
                      <AspectRatio ratio={1} display="block">
                        <Image image={product.thumbnail} objectFit="contain" />
                      </AspectRatio>
                    )}
                    {product.title && (
                      <Heading
                        as="h2"
                        size="3xl"
                        color="white"
                        pt="6"
                        textAlign="center"
                        textTransform="uppercase"
                        transition="text-shadow .3s ease-in-out"
                        sx={{
                          '.product-hover:hover &': {
                            textShadow: { md: `1rem 1rem 0.625rem #053D8F` }
                          }
                        }}>
                        {product.title}
                      </Heading>
                    )}
                    <Box
                      position="absolute"
                      top="1rem"
                      left="50%"
                      width="100%"
                      height="100%"
                      transform="translateX(-50%)"
                      backgroundImage={Glow}
                      backgroundPosition="center"
                      backgroundSize="contain"
                      backgroundRepeat="no-repeat"
                      zIndex="-1"
                      opacity="0"
                      transition="opacity .3s ease-in-out"
                      sx={{
                        '.product-hover:hover &': {
                          opacity: { md: 1 }
                        }
                      }}
                    />
                  </Box>
                </SwiperSlide>
              ))}
            <HStack
              slot="container-end"
              align={{ md: `center` }}
              justifyContent={{ base: `center`, md: `flex-end` }}
              mt={{ base: 8, md: 16 }}
              spacing={{ md: 8 }}>
              <SliderPagination />
              <Hide below="md">
                <SliderArrows />
              </Hide>
            </HStack>
          </Swiper>
        </Wrapper>
      </LazyLoad>
    </Section>
  );
};

export default ProductCarousel;
