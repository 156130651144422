import React from 'react';
import { Box, Heading, SimpleGrid, AspectRatio } from '@chakra-ui/react';
import ButtonGroup from '@components/molecules/ButtonGroup';
import LexicalRichText from '@components/atoms/LexicalRichText';
import Image from '@components/atoms/Image';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import LazyLoad from '@components/atoms/LazyLoad';

const MediaAndText = ({
  data: { title, textContent, images, ctas, alignment }
}) => {
  const isImageRight = alignment === `Image Right`;

  return (
    <Section>
      <LazyLoad>
        <Wrapper>
          <SimpleGrid
            columns={{ md: 2 }}
            spacing={{ base: 10, md: 0 }}
            alignItems="center">
            {images && images.length > 0 && (
              <AspectRatio
                ratio={1}
                overflow="hidden"
                display="block"
                borderRadius="16"
                order={isImageRight ? { md: 2 } : { md: 1 }}>
                <Image image={images[0]} />
              </AspectRatio>
            )}
            <Box
              pl={isImageRight ? null : { md: 12, lg: 32 }}
              pr={isImageRight ? { md: 12, lg: 32 } : null}
              order={isImageRight ? { md: 1 } : { md: 2 }}>
              {title && (
                <Heading
                  as="h2"
                  size="2xl"
                  color="white"
                  textTransform="uppercase">
                  {title}
                </Heading>
              )}
              {textContent && (
                <Box>
                  <LexicalRichText
                    data={{ richText: { ...textContent.richText } }}
                    paddingSides="0"
                    maxWidth="none"
                  />
                </Box>
              )}
              {ctas && ctas.length > 0 && (
                <ButtonGroup
                  ctas={ctas}
                  mt={{ base: 6, md: 8 }}
                  dataLayerType="media_block"
                />
              )}
            </Box>
          </SimpleGrid>
        </Wrapper>
      </LazyLoad>
    </Section>
  );
};

export default MediaAndText;
