import React from 'react';
import { Box, Heading, Grid, GridItem, AspectRatio } from '@chakra-ui/react';
import Share from '@components/molecules/Share';
import { height as headerHeight } from '@components/organisms/Header';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import LazyLoad from '../atoms/LazyLoad';

const ArticleHero = ({
  data: { publishDate, title, image, seo, location = [] }
}) => {
  return (
    <Box
      as="section"
      position="relative"
      bg="colours.colour-1"
      pt={headerHeight}
      mb={{ base: 4, md: 16 }}>
      <LazyLoad>
        <Wrapper>
          <Grid templateColumns={{ md: `repeat(12, 1fr)` }} columnGap={5}>
            {image && (
              <GridItem
                colStart={{ md: 2 }}
                colSpan={{ md: 10 }}
                mb={{ base: 10, md: 12 }}>
                <AspectRatio
                  ratio={4 / 3}
                  overflow="hidden"
                  display="block"
                  borderRadius="2.375rem">
                  <Image image={image} loading="eager" />
                </AspectRatio>
              </GridItem>
            )}
            <GridItem
              colStart={{ md: 3 }}
              colSpan={{ md: 8 }}
              textAlign="center">
              {publishDate && (
                <Heading as="p" variant="detail" size="md" color="white">
                  {publishDate}
                </Heading>
              )}
              {title && (
                <Heading
                  as="h1"
                  color="white"
                  fontSize={{
                    base: `4xl`,
                    lg: `5xl`,
                    xl: `6xl`
                  }}
                  position="relative"
                  mb={10}
                  textTransform="uppercase"
                  textAlign="center"
                  textShadow="0.75rem 0.75rem 0.5rem #084FB6"
                  zIndex="1"
                  _after={{
                    content: `""`,
                    position: `absolute`,
                    width: `100%`,
                    height: `150%`,
                    left: `50%`,
                    top: `50%`,
                    transform: `translate(-50%, -50%)`,
                    background: `colours.colour-5`,
                    filter: `blur(106px)`,
                    zIndex: `-1`
                  }}>
                  {title}
                </Heading>
              )}
              <Share
                position="relative"
                zIndex="1"
                data={{
                  metaTitle: seo.metaTitle,
                  metaDescription: seo.metaDescription,
                  link: location.href
                }}
              />
            </GridItem>
          </Grid>
        </Wrapper>
      </LazyLoad>
    </Box>
  );
};

export default ArticleHero;
