import React from 'react';
import Wrapper from '@components/atoms/Wrapper';
import Section from '@components/atoms/Section';
import ProductListingRow from '../molecules/ProductListingRow';

const ProductListing = ({ products, parentSlug, locale }) => {
  return (
    <Section>
      <Wrapper maxW="100rem" px={{ base: 6 }}>
        {typeof products !== `undefined` &&
          products.map((product, index) => {
            // Determine if product index value is odd or even
            const isEven = index % 2 === 0;
            return (
              <ProductListingRow
                key={product.slug}
                {...product}
                isEven={isEven}
                parentSlug={parentSlug}
                locale={locale}
              />
            );
          })}
      </Wrapper>
    </Section>
  );
};

export default ProductListing;
