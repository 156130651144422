import React from 'react';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@components/atoms/icons';

const hoverStyles = {
  bg: null
};

const iconHoverStyles = {
  '.chakra-button:hover &': {
    color: `black`
  },
  // Prevent disabled icon from changing colour on hover state.
  '.chakra-button.swiper-button-disabled:hover &': {
    color: `white`
  }
};

const SliderArrows = () => {
  return (
    <ButtonGroup
      mt={{ base: `auto`, md: `0` }}
      sx={{
        '> .swiper-button-disabled[disabled]': {
          opacity: 1
        },
        '> .swiper-button-disabled[disabled] > svg': {
          opacity: 0.5
        }
      }}
      isAttached>
      <Button
        className="prev"
        color="white"
        bgColor="colours.colour-1-muted"
        p="4"
        pr="2.5"
        borderTopLeftRadius="2.938rem"
        borderBottomLeftRadius="2.938rem"
        _hover={hoverStyles}>
        <ArrowLeftIcon color="white" w="6" h="6" sx={iconHoverStyles} />
      </Button>
      <Button
        className="next"
        color="white"
        bgColor="colours.colour-1-muted"
        p="4"
        pl="2.5"
        borderTopRightRadius="2.938rem"
        borderBottomRightRadius="2.938rem"
        _hover={hoverStyles}>
        <ArrowRightIcon color="white" w="6" h="6" sx={iconHoverStyles} />
      </Button>
    </ButtonGroup>
  );
};

export default SliderArrows;
