export const components = {
  Heading: {
    baseStyle: {
      color: `white`,
      mb: { base: 4, md: 6 }
    },
    sizes: {
      xs: {
        fontSize: [`xs`, `xs`, `sm`]
      },
      sm: {
        fontSize: [`sm`, `sm`, `md`]
      },
      md: {
        fontSize: [`lg`, `lg`, `xl`]
      },
      lg: {
        fontSize: [`xl`, `xl`, `2xl`]
      },
      xl: {
        fontSize: `3xl`
      },
      '2xl': {
        fontSize: [`3xl`, `3xl`, `4xl`]
      },
      '3xl': {
        fontSize: [`4xl`, `4xl`, `5xl`]
      },
      '4xl': {
        fontSize: [`4xl`, `4xl`, `5xl`, `6xl`]
      },
      '5xl': {
        fontSize: [`4xl`, `4xl`, `6xl`, `8xl`],
        lineHeight: `none`
      }
    },
    variants: {
      detail: {
        fontFamily: `body`,
        fontWeight: `600`
      }
    }
  },
  Text: {
    baseStyle: {
      mb: { base: 4, md: 6 }
    }
  },
  List: {
    baseStyle: {
      container: {
        mb: { base: 4, md: 6 }
      }
    }
  },
  Button: {
    defaultProps: {
      colorScheme: null
    },
    baseStyle: {
      position: `relative`,
      fontFamily: `heading`,
      textTransform: `uppercase`,
      cursor: `pointer`
    },
    sizes: {
      ellipsedSmall: {
        fontSize: `md`
      },
      ellipsedMedium: {
        fontSize: `2xl`
      },
      sm: ({ variant }) => ({
        h: variant === `primary` || `secondary` ? `3.438rem` : null,
        px: variant === `primary` || `secondary` ? `2.375rem` : null,
        minW: null,
        fontSize: `md`,
        '.button__inner': {
          h: variant === `primary` || `secondary` ? `1.188rem` : null
        }
      }),
      md: ({ variant }) => ({
        h: variant === `primary` || `secondary` ? `4.5rem` : null,
        px: variant === `primary` || `secondary` ? `4rem` : null,
        minW: null,
        fontSize: `xl`,
        '.button__inner': {
          h: variant === `primary` || `secondary` ? `1.625rem` : null
        }
      })
    },
    variants: {
      primary: {
        backgroundColor: `transparent`,
        color: `white`,
        borderRadius: `5.5rem`,
        '&::after': {
          content: `""`,
          position: `absolute`,
          top: `0`,
          bottom: `0`,
          left: `0`,
          right: `0`,
          backgroundColor: `black`,
          borderRadius: `5.5rem`,
          zIndex: `-1`,
          transition: `transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)`
        },
        '.button__inner': {
          display: `block`,
          position: `relative`,
          transition: `transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)`,
          '&::after': {
            content: `attr(data-text)`,
            display: `block`
          }
        },
        _hover: {
          '&::after': {
            backgroundColor: { md: `white` },
            transform: { md: `scale(1.1)` },
            boxShadow: { md: `16px 16px 10px 0px #08409B` }
          },
          '.button__inner': {
            transform: { md: `translateY(-100%)` },
            '&::after': {
              color: { md: `colours.colour-1` }
            }
          }
        }
      },
      secondary: {
        backgroundColor: `transparent`,
        color: `white`,
        borderRadius: `5.5rem`,
        '&::after': {
          content: `""`,
          position: `absolute`,
          top: `0`,
          bottom: `0`,
          left: `0`,
          right: `0`,
          backgroundColor: `transparent`,
          borderStyle: `solid`,
          border: `2px solid`,
          borderColor: `colours.colour-2`,
          borderRadius: `5.5rem`,
          zIndex: `-1`,
          transition: `transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)`
        },
        '.button__inner': {
          display: `block`,
          position: `relative`,
          transition: `transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)`,
          '&::after': {
            content: `attr(data-text)`,
            display: `block`
          }
        },
        _hover: {
          '&::after': {
            backgroundColor: { md: `colours.colour-1-muted` },
            borderColor: { md: `transparent` },
            transform: { md: `scale(1.1)` },
            boxShadow: { md: `16px 16px 10px 0px #08409B` }
          },
          '.button__inner': {
            transform: { md: `translateY(-100%)` }
          }
        }
      },
      tertiary: {
        color: `white`
      },
      quaternary: {
        color: `white`,
        border: `0.125rem solid`,
        borderColor: `colours.colour-2`
      }
    }
  },
  Link: {
    baseStyle: {
      _hover: {
        textDecoration: `none`
      }
    },
    variants: {
      header: {
        color: `secondary.pink`,
        fontWeight: `bold`,
        textDecoration: `underline`,
        _hover: {
          textDecoration: `underline`
        }
      }
    }
  },
  Radio: {
    baseStyle: {
      control: {
        borderWidth: `1px`,
        borderColor: `primary.dark-blue`,
        _checked: {
          background: `primary.dark-blue`,
          borderColor: `primary.dark-blue`,
          _hover: {
            background: `primary.dark-blue`,
            borderColor: `primary.dark-blue`
          }
        }
      },
      label: {
        fontFamily: `heading`,
        fontWeight: `bold`,
        color: `secondary.pink`,
        textDecoration: `underline`
      }
    },
    sizes: {
      md: {
        control: {
          w: 6,
          h: 6
        }
      }
    }
  },
  Checkbox: {
    baseStyle: {
      control: {
        borderWidth: `1px`,
        borderColor: `primary.dark-blue`,
        borderRadius: 4,
        _checked: {
          background: `primary.dark-blue`,
          borderColor: `primary.dark-blue`,
          _hover: {
            background: `primary.dark-blue`,
            borderColor: `primary.dark-blue`
          }
        }
      },
      label: {
        fontFamily: `heading`,
        fontWeight: `bold`,
        color: `secondary.pink`,
        textDecoration: `underline`
      }
    },
    sizes: {
      md: {
        control: {
          w: 6,
          h: 6
        }
      }
    }
  }
};
