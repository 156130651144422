import React, { useCallback, useContext } from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import {
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  ListItem,
  UnorderedList,
  useDisclosure
} from '@chakra-ui/react';
import { height as headerHeight } from '@components/organisms/Header';
import Wrapper from '@components/atoms/Wrapper';
import Image from '@components/atoms/Image';
import { LocaleContext } from '@helpers/LocaleProvider';
import { ChevronDownIcon } from '@chakra-ui/icons';
import dataLayerPush from '@helpers/dataLayerPush';
import LazyLoad from '../atoms/LazyLoad';

const ProductHero = ({
  title,
  subtitle,
  description,
  image,
  productVariants,
  productSize
}) => {
  // Sets the current locale .
  const { locale } = useContext(LocaleContext);

  // Query allStrapiProductListing to get slug of product listing page.
  const { allStrapiProductListing } = useStaticQuery(graphql`
    {
      allStrapiProductListing {
        nodes {
          locale
          slug
        }
      }
    }
  `);

  // Find matching locale.
  const strapiProductListing = allStrapiProductListing.nodes.find(
    (node) => node.locale === locale
  );
  const basePath = `${locale}/${strapiProductListing.slug}`;

  // Set state for popover.
  const { isOpen, onToggle, onClose } = useDisclosure();

  // Handle product variant change.
  const handleLocationChange = useCallback(
    (productSlug) => {
      navigate(`/${basePath}/${productSlug}`);
      dataLayerPush({
        event: `product_variant_link`,
        interaction: {
          click_text: `Change product variant`,
          location: productSlug
        }
      });
    },
    [basePath]
  );

  return (
    <Box
      as="section"
      position="relative"
      bg="colours.colour-1"
      bgGradient="linear(to-b, #183788, colours.colour-1)"
      _after={{
        content: `""`,
        position: `absolute`,
        width: `60%`,
        height: `70%`,
        left: `50%`,
        top: `50%`,
        transform: `translate(-50%, -50%)`,
        background: `colours.colour-5`,
        filter: `blur(106px)`,
        zIndex: `0`
      }}>
      <Wrapper>
        <Grid
          className="grid"
          position="relative"
          templateColumns={{ md: `repeat(12, 1fr)` }}
          columnGap={5}
          alignItems="center"
          height={{ md: `100vh` }}
          minHeight={{ lg: `44rem` }}
          maxHeight={{ lg: `56.25rem` }}
          pt={{ base: `6.625rem`, md: headerHeight }}
          pb={{ base: 10, md: 24 }}
          zIndex={1}>
          <GridItem
            order={{ md: 2 }}
            colStart={{ xl: 7 }}
            colSpan={{ md: 6 }}
            mb={{ base: 6, md: 0 }}
            sx={{
              '> .gatsby-image-wrapper': {
                flexGrow: 1
              }
            }}>
            <LazyLoad>
              {image && <Image image={image} loading="eager" />}
            </LazyLoad>
          </GridItem>
          <GridItem
            position="relative"
            order={{ md: 1 }}
            colStart={{ xl: 2 }}
            colSpan={{ md: 6, xl: 4 }}>
            {title && (
              <LazyLoad>
                <Heading
                  as="h1"
                  fontSize={{
                    base: `6xl`,
                    lg: `7xl`
                  }}
                  lineHeight="1.1"
                  mb="8"
                  textTransform="uppercase"
                  textShadow="0.75rem 0.75rem 0.5rem #084FB6">
                  {title}
                </Heading>
              </LazyLoad>
            )}
            {subtitle && (
              <LazyLoad>
                <Text fontSize="2xl" mb="4" fontWeight="900">
                  {subtitle}
                </Text>
              </LazyLoad>
            )}
            {productVariants?.length > 0 && (
              <LazyLoad position="relative" zIndex="1">
                <Popover isOpen={isOpen} onClose={onClose}>
                  <Box
                    as="button"
                    display="flex"
                    alignItems="center"
                    fontSize="2xl"
                    mb="4"
                    fontWeight="900"
                    onClick={onToggle}>
                    {productSize}
                    <PopoverTrigger>
                      <ChevronDownIcon boxSize={7} color="white" ml={1} />
                    </PopoverTrigger>
                  </Box>
                  <PopoverContent
                    width="100%"
                    minWidth="12.3125rem"
                    borderRadius={8}
                    pt={6}
                    px={6}
                    mt={2}>
                    <PopoverArrow />
                    <PopoverBody pt={0} px={0} pb={6} overflow="auto">
                      <UnorderedList listStyleType="none" ml={0} mb={0}>
                        {productVariants.map((variant) => {
                          return (
                            variant.product !== null && (
                              <ListItem
                                key={variant.id}
                                as="button"
                                display="flex"
                                alignItems="center"
                                width="100%"
                                p={2}
                                mb={1}
                                mr={0}
                                color={
                                  variant.currentVariant
                                    ? `white`
                                    : `colours.colour-1`
                                }
                                fontFamily="heading"
                                fontSize="sm"
                                textTransform="uppercase"
                                borderRadius={4}
                                backgroundColor={
                                  variant.currentVariant
                                    ? `colours.colour-1`
                                    : `transparent`
                                }
                                cursor="pointer"
                                onClick={() => {
                                  return handleLocationChange(
                                    variant.product.slug
                                  );
                                }}
                                _hover={{
                                  backgroundColor: `colours.colour-4`,
                                  color: `colours.colour-1`
                                }}>
                                {variant.productVarientSize}
                              </ListItem>
                            )
                          );
                        })}
                      </UnorderedList>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </LazyLoad>
            )}
            {description && (
              <LazyLoad>
                <Text fontSize="xl">{description}</Text>
              </LazyLoad>
            )}
          </GridItem>
        </Grid>
      </Wrapper>
    </Box>
  );
};

export default ProductHero;
