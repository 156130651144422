import React, { useContext } from 'react';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import { Box, Flex, Text, Image, keyframes } from '@chakra-ui/react';
import { LocaleContext } from '@helpers/LocaleProvider';
import FooterNav from '@components/molecules/FooterNav';
import SocialNav from '@components/molecules/SocialNav';
import Wrapper from '@components/atoms/Wrapper';
import logo from '@assets/images/oreo-logo.png';
import LogoBackground from '@assets/images/oreo-logo-background.png';
import LazyLoad from '../atoms/LazyLoad';

// Keyframe Animations.
const logoMilkSwoosh = keyframes`
    0% {background-position: 0 0;}
    100% {background-position: -84.45rem 0;}
`;
const logoScale = keyframes`
  0% {transform: scale(1);}
  50% {transform: scale(1.1);}
  100% {transform: scale(1);}
`;

const Footer = ({ footerMenu }) => {
  const {
    site: {
      siteMetadata: { title: siteTitle }
    },
    allStrapiFooter
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
      allStrapiFooter {
        nodes {
          locale
          copyright
          socialLinks {
            title
            youtubeLink
            instagramLink
            facebookLink
          }
        }
      }
    }
  `);

  /*
   * Sets the current locale .
   */
  const { locale } = useContext(LocaleContext);

  if (!allStrapiFooter) {
    return null;
  }

  /*
   * Find matching locale for copyright content.
   */
  const strapiFooter = allStrapiFooter.nodes.find(
    (node) => node.locale === locale
  );

  if (typeof strapiFooter === `undefined`) {
    console.error(`Unable to find footer data for ${locale}.`);
    return null;
  }

  const { copyright } = strapiFooter;
  const { socialLinks } = strapiFooter;

  return (
    <Box as="footer" bg="colours.colour-1" color="white" pt={20}>
      <LazyLoad>
        <Wrapper maxWidth="none">
          <Flex
            direction={{
              base: `column`,
              xl: `row-reverse`
            }}
            alignItems={{ lg: `flex-start` }}
            justifyContent={{ md: `space-between` }}
            py="2.375rem"
            px={{ lg: `3.75rem` }}
            borderTop="1px"
            borderColor="colours.colour-2">
            <Flex
              width={{ lg: `100%`, xl: `auto` }}
              direction={{ base: `column`, lg: `row` }}
              justifyContent={{ lg: `space-between` }}
              gap={{ lg: `6.6rem` }}>
              <FooterNav locale={locale} footerMenu={footerMenu} />
              <SocialNav
                locale={locale}
                socialLinks={socialLinks}
                mt={{ base: 6, lg: 0 }}
              />
            </Flex>
            <Box mt={{ base: 10, xl: 0 }} mr={{ md: 10 }}>
              <Flex
                as={GatsbyLink}
                to={`/${locale}`}
                className="logo-hover"
                position="relative"
                width={{ base: `5.676rem`, md: `8.375rem` }}
                mx={{ base: `auto`, lg: 0 }}
                mb={{ base: 6, md: 2 }}
                zIndex="2">
                <Box
                  display={{ base: `none`, md: `block` }}
                  position="absolute"
                  top="-3.125rem"
                  left={0}
                  width="7.675rem"
                  height="8.375rem"
                  backgroundImage={LogoBackground}
                  backgroundRepeat="no-repeat"
                  transition="background-position ease-in-out .2s"
                  opacity={0}
                  sx={{
                    '.logo-hover:hover &': {
                      opacity: 1,
                      animation: `${logoMilkSwoosh} 1s steps(10) forwards`
                    }
                  }}
                />
                <Image
                  src={logo}
                  alt={siteTitle}
                  transform="scale(1)"
                  transition="transform 0.3s ease-out"
                  sx={{
                    '.logo-hover:hover &': {
                      animation: `${logoScale} .7s ease-out`
                    }
                  }}
                />
              </Flex>
              {copyright && (
                <Text
                  fontSize="sm"
                  mb="0"
                  textAlign={{ base: `center`, lg: `left` }}>
                  {copyright}
                </Text>
              )}
            </Box>
          </Flex>
        </Wrapper>
      </LazyLoad>
    </Box>
  );
};

export default Footer;
