import React from 'react';
import { Grid, GridItem, AspectRatio, Text, Link } from '@chakra-ui/react';
import Image from '@components/atoms/Image';
import Section from '@components/atoms/Section';
import Wrapper from '../atoms/Wrapper';
import LazyLoad from '../atoms/LazyLoad';

const WhereToBuy = ({ data: { retailers } }) => {
  return (
    <Section>
      <LazyLoad>
        <Wrapper position="relative" zIndex="1">
          <Grid
            templateColumns="repeat(12, 1fr)"
            columnGap={{ base: 5, sm: 8, md: 10 }}
            rowGap={8}
            alignItems={{ md: `baseline` }}>
            {typeof retailers !== `undefined` &&
              retailers.map((retailer) => (
                <GridItem
                  key={retailer.id}
                  colSpan={{ base: 6, lg: 3 }}
                  className="item-hover">
                  <Link
                    href={retailer.link}
                    isExternal
                    display="block"
                    transition="transform .5s cubic-bezier(0.53, 2, 0.63, 0.53)"
                    sx={{
                      '.item-hover:hover &': {
                        transform: { md: `auto` },
                        scale: { md: `1.1` }
                      }
                    }}>
                    {retailer.image && (
                      <AspectRatio
                        ratio={1 / 1}
                        borderRadius="1rem"
                        overflow="hidden">
                        <Image image={retailer.image} />
                      </AspectRatio>
                    )}
                    <Text
                      fontFamily="heading"
                      fontSize={{ base: `lg`, md: `3xl` }}
                      pt={{ base: 2, md: 4 }}
                      textAlign="center"
                      textTransform="uppercase"
                      mb={0}>
                      {retailer.title}
                    </Text>
                  </Link>
                </GridItem>
              ))}
          </Grid>
        </Wrapper>
      </LazyLoad>
    </Section>
  );
};

export default WhereToBuy;
