import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import Wrapper from '@components/atoms/Wrapper';
import Video from '@components/atoms/Video';
import Image from '@components/atoms/Image';

const ImageComponent = ({ data }) => (
  <Flex alignItems="center" justifyContent="center">
    <Box borderRadius="2rem" overflow="hidden" mt="12" mb="20">
      <Image image={data.image} />
    </Box>
  </Flex>
);

const VideoComponent = ({ data }) => {
  const { videoCover } = data;
  return (
    <Video data={data} maxLoops={1}>
      <Image image={videoCover} loading="eager" />
    </Video>
  );
};

const MediaComponents = {
  'media.image': ImageComponent,
  'media.video': VideoComponent
};

const Media = ({ data }) => {
  const { strapiComponent, ...props } = data;
  const TagName = MediaComponents[String(strapiComponent)];

  if (typeof TagName === `undefined`) {
    return (
      <div key={`media-component-${strapiComponent} `}>
        Component <b>{strapiComponent}</b> not found
      </div>
    );
  }

  return (
    <Wrapper maxWidth="61.625rem">
      <TagName
        key={`media-component-${strapiComponent}`}
        data={{ strapiComponent, ...props }}
      />
    </Wrapper>
  );
};

export default Media;
