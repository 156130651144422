import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { Fonts } from '@components';
import { theme } from '@styles/theme';
import LocaleProvider from '@helpers/LocaleProvider';

export const wrapPageElement = ({ element, props }) => {
  const {
    pageContext: { locale = `en`, locales = [] }
  } = props;
  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <LocaleProvider locale={locale} locales={locales}>
        {element}
      </LocaleProvider>
    </ChakraProvider>
  );
};
