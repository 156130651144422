import React from 'react';
import { Global } from '@emotion/react';
import PlutoCondensedBlack from '@assets/fonts/Pluto-Cond-Black.woff2';
import MyriadPro from '@assets/fonts/MyriadPro-Regular.woff2';
import MyriadProSemibold from '@assets/fonts/MyriadPro-Semibold.woff2';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Pluto Condensed';
        font-weight: 700;
        font-style: normal;
        font-display: swap;
        src: url(${PlutoCondensedBlack}) format('woff2');
      }
      @font-face {
        font-family: 'Myriad Pro';
        font-weight: 400;
        font-style: normal;
        font-display: swap;
        src: url(${MyriadPro}) format('woff2');
      }
      @font-face {
        font-family: 'Myriad Pro';
        font-weight: 900;
        font-style: normal;
        font-display: swap;
        src: url(${MyriadProSemibold}) format('woff2');
      }
    `}
  />
);

export default Fonts;
